@background-color: rgba(245, 246, 250, 1);

.site-layout-background {
  min-height: 280;
  // border-width: 0px 0px 0px 1px;
  // border-style: solid;
  // border-color: lighten(#7f8c8d, 20%);
  padding: 24px;
  overflow-y: auto;
  background: #fff;
}

.报表-page {
  background: none !important;
}

.工时填报-page {
  background: none !important;
}

.设置-page {
  background: none !important;
}

.ant-breadcrumb.breadcrumb-area {
  padding: 15px 20px;
  margin-top: -5px;
  // margin: 0;
  background: white;
  border-left: 2px solid #fafafa;
}

.site-layout {
  &.ant-layout {
    background: @background-color;
  }

  // margin-left: 230px;
  transition: 0.2s ease;

  &.collapsed {
    // margin-left: 72px;
    transition: 0.6s ease-in-out;
  }
}

#logo-menu {
  &.ant-menu-item {
    width: 163px;
    height: 63px;
    // 强制重置 menuItem 的样式
    padding: 0 !important;
    margin: 0;
    font-size: 14px;
  }
}

.logo {
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 19px;
  font-weight: 400;
  color: white;
  background: #005dd8;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 1;
}

.container {
  inset: 0;
  min-height: 100vh;
  margin: auto;
  background-color: black;

  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;
    min-height: 55px;
    padding: 0 24px;
    margin: 0;
    color: white;
    background: white;
    flex-direction: row;
    // box-shadow: 0px 0 6px 0 #E2E2EA;
    // box-shadow: 0px -1px 0px 0px #E2E2EA inset;

    // border-bottom: 1px solid #b5bcbd;

    .anticon-translation {
      position: absolute;
      top: 20px;
      right: 24px;
      z-index: 10;
      font-size: 19px;
      color: #fff;
    }
  }

  .div-header-setting {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0 0 0);

    .locale-dropdown {
      color: rgb(0 0 0);
      cursor: pointer;
    }
  }

  .ant-layout-footer {
    padding: 5px;
    font-size: 13px;
    color: #222;
    text-align: center;
    // display: flex;
    // justify-content: space-between;
    // background-color: #2980b922;
    // border-style: solid;
    // border-width: 1px 0 0 1px;
    // border-color: lighten(#7f8c8d, 20%);

    a {
      font-weight: bolder;
      color: #2c3e50;
    }
  }


  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-head-wrapper {
    border-bottom: 1px solid #f0f0f0;
  }
}

.layout-menu {
  .ant-menu-item-selected::after {
    border-bottom: 2px solid #0068eb !important;
  }

}

.ant-layout-header {
  .iconfont-icon-lang {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    padding: 0;
    font-size: 14px;
    color: #00000065;
  }
}

@primary-color: #1890ff;