/* stylelint-disable no-descending-specificity */
@import url('~antd/dist/antd.less');
@main-color: #fff !important;

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

/* 按钮 */
.ant-btn-primary:hover {
  background: #0068EB;
}

.ant-btn {
  border-radius: 8px !important;
}

.ant-btn-lg {
  height: 40px;
  border-radius: 6px;
}

.ant-btn-sm {
  padding: 1px 8px;
  border-radius: 3.5px;
}

/*
  Selector鼠标悬停时展示option
*/
.locale {
  position: absolute;
  right: 36px;
  display: flex;
  align-items: center;
}

.locale-dropdown {
  color: #00000065;
}

.locale-dropdown:hover {
  color: #0068EB;
}

.ant-dropdown-menu-item:hover {
  background: #FFF !important;
}

/*
  menu侧边栏
*/

// 侧边栏 除去breadcrumb部分, layout > index.less也有涉及
.ant-menu:not(.ant-card-body .ant-menu) {
  color: #000 !important;
  background: @main-color;
  border-radius: 8px;
}


.ant-menu-inline {
  padding-top: 20px;
}

.container .ant-layout-sider-has-trigger,
.container .ant-layout-sider {
  // box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  user-select: none;
}


// 侧边栏每一个submenu
.ant-menu-submenu-popup>.ant-menu {
  width: 150px;
}

// 侧边栏里的条目
.ant-menu-submenu-title,
.ant-menu-item a {
  font-style: normal;
  font-weight: 600;
}

.ant-menu-item,
.ant-menu-submenu-title {
  transition: border-color 0s, background 0s,
    padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

  &::after {
    border-right: none !important;
  }
}


.ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: visible !important;
  text-overflow: ellipsis;
}

// 侧边栏里的条目hover和selected效果
.ant-menu-item a:hover,
.ant-menu-submenu-title:hover {
  color: #0068EB !important;
}

// 侧边栏里的条目的下拉箭头
.ant-menu-submenu-arrow {
  color: #000 !important;
}

// 侧边栏里选中的条目
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  a {
    color: #0068EB !important;
  }

  .ant-menu-submenu-title:hover {
    color: #0068EB !important;
  }
}

.ant-menu-horizontal .ant-menu-item-selected {
  a {
    color: #0068EB !important;
  }
}

// 侧边栏里选中的条目的蓝边
.ant-menu-inline .ant-menu-item::after {
  border-right: 0 solid white;
}

/*
  主页面
*/
// 表格外的container
.ant-layout-content {
  padding: 0;
  margin: 20px 20px 0;
  border-radius: 3px;
  border-radius: 8px;
}

//表格
.table-container {
  margin: 0 10px;
  overflow-y: auto;
}

// // 进度条
// .ant-progress-status-success .ant-progress-bg {
//   background-color: #1890ff;
// }

.site-card-wrapper {
  margin: 10px;
}

// 滚动条背景
.ant-layout-sider,
.ant-layout-sider-light,
.ant-layout-sider-has-trigger::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: @main-color;
}

.ant-layout-sider,
.ant-layout-sider-light,
.ant-layout-sider-has-trigger::-webkit-scrollbar-corner {
  background-color: @main-color;
}

// .ant-card-body {
//   padding-left: 5px !important;
//   padding-right: 5px !important;
// }
.ant-card-head-title {
  font-size: 16px !important;
}

.ant-layout .ant-layout-has-sider {
  // box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 6px rgb(0 0 0 / 25%);
}

.ant-card {
  height: 100%;
}

.ant-layout-header {
  .col-dotted-line {
    margin: 18px 8px 18px 24px;
    border-style: solid;
    border-width: 0 1px 0 0;
    border-color: rgb(0 0 0 / 12%);
  }
}

// 飞书头像样式
.feishu-head-picture {
  width: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
  border-radius: 20px;
}

@primary-color: #1890ff;